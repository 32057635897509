import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import { PhotoSwipeGallery } from "react-photoswipe"
import "react-photoswipe/lib/photoswipe.css"
import "./gallery.scss"
const SecondPage = () => (
  <Layout>
    <SEO title="Listino pizze" />
    <MenuPages />
  </Layout>
)

export default SecondPage

const MenuPages = props => {
  let options = {
    //http://photoswipe.com/documentation/options.html
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(filter: { relativePath: { glob: "pics/pub_*" } }) {
            edges {
              node {
                id
                relativePath
                publicURL
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <h1>Gallery</h1>
          <p>Il nostro locale...</p>
          <PhotoSwipeGallery
            items={data.images.edges
              .filter(e => e.node.relativePath.startsWith("pics/pub_locale"))
              .map(e => ({
                src: e.node.publicURL,
                thumbnail: e.node.publicURL,
                w: 0,
                h: 0,
              }))}
            options={options}
            thumbnailContent={getThumbnailContent}
            gettingData={adaptLargeImageSize}
          />
          <p>e qualche esempio di pizze speciali</p>

          <PhotoSwipeGallery
            items={data.images.edges
              .filter(e => e.node.relativePath.startsWith("pics/pub_pizza"))
              .map(e => ({
                src: e.node.publicURL,
                thumbnail: e.node.publicURL,
                w: 0,
                h: 0,
              }))}
            options={options}
            thumbnailContent={getThumbnailContent}
            gettingData={adaptLargeImageSize}
          />
        </div>
      )}
    />
  )
}

function getThumbnailContent(item) {
  return <img src={item.thumbnail} className="thumbnail" alt={item.title} />
}

function adaptLargeImageSize(photoswipe, index, item) {
  if (item.w < 1 || item.h < 1) {
    // unknown size
    var img = new Image()
    img.onload = function() {
      // will get size after load
      item.w = this.width // set image width
      item.h = this.height // set image height
      photoswipe.invalidateCurrItems() // reinit Items
      photoswipe.updateSize(true) // reinit Items
    }
    img.src = item.src // let's download image
  }
}
